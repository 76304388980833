import React, { useState } from "react";
import "./MiddleSection1.css"; // Import CSS for placeholders

const MiddleSection1 = (props) => {
  let {
    smallText,
    mainText,
    image,
    detailText,
    titleHead1,
    description1,
    titleHead2,
    description2,
    titleHead3,
    description3,
    titleHead4,
    description4,
  } = props[0].Info;

  return (
    <section className="section-box rtl">
      <div className="container mt-100">
        <div className="row">
          <div className="col-lg-6 col-sm-12 block-img-we-do">
            <ImageWithPlaceholder
              className="bdrd-16 img-responsive"
              src={`${process.env.REACT_APP_API_URL}/${image}`}
              alt={props.pageTitle}
            />
          </div>
          <div className="col-lg-6 col-sm-12 block-we-do">
            <h3 className="text-heading-1 mt-30">{mainText}</h3>
            <p className="text-body-lead-large color-gray-600 mt-30">
              {detailText}
            </p>
            <div className="line-bd-green mt-50" />
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{titleHead1}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description1}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{titleHead2}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description2}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{titleHead3}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description3}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 col-12 mt-50">
                <h4 className="text-heading-6 icon-leaf">{titleHead4}</h4>
                <p className="text-body-excerpt color-gray-600 mt-15">
                  {description4}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageWithPlaceholder = ({ src, alt, className = "", style = {} }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="image-wrapper" style={style}>
      {!isLoaded && <div className="image-placeholder"></div>}
      <img
        src={src}
        alt={alt}
        className={`${className} ${isLoaded ? "placeholder-loaded" : "placeholder-hidden"}`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default MiddleSection1;
