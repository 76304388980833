import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SingleBlog(props) {
  const { t } = useTranslation();

  let data = props[0].Data;
  let { backgroundImg, title } = props[0].Info;
  const currentLanguage = localStorage.getItem("i18nextLng") || "en";

  // State to manage the image load status
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Placeholder image (can be a local spinner, static placeholder, or external resource)
  const placeholder = "/assets/imgs/placeholder.jpg";

  return (
    <>
      <section className="section-box">
        <div
          className="banner-hero banner-head-image"
          style={{ background: "url(assets/imgs/page/blog/single/banner.png)" }}
        >
          <div className="container">
            <div className="text-center">
              <h1 className="text-heading-1 color-white mt-30">{title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50 mb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-12" />
            <div className="col-lg-1 col-md-2 col-sm-2 col-3 text-center">
              <div className="social-sticky">
                <h3 className="text-heading-6 color-gray-400 mb-20 mt-5">
                  {t("share")}
                </h3>
                <Link to="https://facebook.com" target="_blank">
                  <a className="share-social share-fb"></a>
                </Link>
                <br />
                <Link to="https://twitter.com" target="_blank">
                  <a className="share-social share-tw"></a>
                </Link>
                <br />
                <Link to="https://www.pinterest.com" target="_blank">
                  <a className="share-social share-pi"></a>
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-10 col-9">
              <div className="text-summary">
                {currentLanguage === "en" ? data.titleEn : data.titleAr}
              </div>
              {/* Image with placeholder */}
              <div style={{ position: "relative", width: "100%", height: "250px" }}>
                {/* Placeholder */}
                {!isImageLoaded && (
                  <img
                    style={{
                      width: "100%",
                      height: "250px",
                      borderRadius: "15px",
                      objectFit: "cover",
                      position: "absolute",
                    }}
                    src={placeholder}
                    alt="Placeholder"
                  />
                )}
                {/* Actual Image */}
                <img
                  style={{
                    width: "100%",
                    height: "250px",
                    borderRadius: "15px",
                    objectFit: "cover",
                    display: isImageLoaded ? "block" : "none",
                  }}
                  src={`${process.env.REACT_APP_API_URL}/uploads/${data.thumbnail}`}
                  alt={data.titleEn}
                  onLoad={() => setIsImageLoaded(true)} // Trigger onLoad
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="single-detail mt-50">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      currentLanguage === "en"
                        ? data.contentEn
                        : data.contentAr,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SingleBlog;
